import { defineStore } from 'pinia'

type TToast = 'info' | 'warn' | 'error' | 'success'

interface TToastConfig {
  title?: string
  description?: string
  type: TToast
  isDark?: boolean
  canReload?: boolean
}

export const useToast = defineStore('toast', () => {
  const isVisible = ref(false)
  const config = reactive<TToastConfig>({
    title: undefined,
    description: undefined,
    type: 'info',
    isDark: false,
    canReload: false,
  })

  let timer: ReturnType<typeof setTimeout> | null = null

  const render = {
    start() {
      if (timer) {
        clearTimeout(timer)
      }
      isVisible.value = true
      timer = setTimeout(() => {
        hide()
        timer = null
      }, 5000)
    },
    stop() {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    },
  }

  function show(options: TToastConfig) {
    config.type = options.type
    config.canReload = options.canReload ? options.canReload : false
    config.title = options.title ? options.title : undefined
    config.description = options.description ? options.description : undefined
    config.isDark = options.isDark ? options.isDark : config.isDark

    render.start()
  }

  function success(message: string, title?: string) {
    hide()
    setTimeout(() => {
      show({
        title: title || 'Успешно',
        description: message,
        type: 'success',
      })
    }, 100)
  }

  function warn(message: string, title?: string) {
    hide()
    setTimeout(() => {
      show({
        title: title || 'Внимание',
        description: message,
        type: 'warn',
      })
    }, 100)
  }

  function info(message: string, title?: string) {
    hide()
    setTimeout(() => {
      show({
        title: title || 'Информация',
        description: message,
        type: 'info',
      })
    }, 100)
  }

  function error(message: string, title?: string, canReload?: boolean) {
    hide()
    setTimeout(() => {
      show({
        title: title || 'Ошибка',
        description: message,
        canReload,
        type: 'error',
      })
    }, 100)
  }

  function hide() {
    isVisible.value = false
    render.stop()
  }

  return {
    isVisible,
    config,
    show,
    hide,
    success,
    warn,
    info,
    error,
    render,
  }
})
