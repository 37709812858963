<script setup lang="ts">
import { useToast } from '~/store/toast'

const toast = useToast()
const isHiding = ref(false)

function reload() {
  reloadNuxtApp({
    force: true,
  })
}

function startTimer() {
  if (!isHiding.value) {
    toast.render.start()
  }
}

function hide() {
  isHiding.value = true
  setTimeout(() => {
    isHiding.value = false
  }, 500)
  toast.hide()
}
</script>

<template>
  <Teleport to="body">
    <transition name="toast">
      <div
        v-if="toast.isVisible"
        class="fixed top-14 right-5 flex gap-2 pt-2 px-3 pb-3 shadow-md border-[1px] border-secondary-300 hover:border-secondary-500 bg-white rounded-xl duration-150"
        @mouseenter="toast.render.stop"
        @mouseleave="startTimer"
      >
        <nuxt-icon v-if="toast.config.type" class="mt-0.5" :name="`toast/${toast.config.type}`" filled />
        <div class="flex flex-col cursor-default">
          <h1 class="text-sm font-medium" v-text="toast.config.title" />
          <p class="text-sm max-w-56" v-text="toast.config.description" />
          <p v-show="toast.config.canReload" class="text-sm opacity-50 hover:opacity-100 duration-150 cursor-pointer" @click="reload">
            перезагрузить страницу
          </p>
        </div>
        <svg
          class="mt-0.5 close cursor-pointer" width="10" height="10" viewBox="0 0 8 8" fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="hide"
        >
          <path d="M7.0049 6.65195L6.65196 7.00489L0.995101 1.34804L1.34804 0.995097L7.0049 6.65195Z" />
          <path d="M6.65196 0.995095L7.0049 1.34804L1.34804 7.00489L0.995101 6.65195L6.65196 0.995095Z" />
        </svg>
      </div>
    </transition>
  </Teleport>
</template>

<style scoped>
.toast-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.toast-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.toast-leave-from {
  opacity: 1;
}

.toast-leave-to {
  opacity: 0;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 150ms ease;
}

.close path {
  transition: fill 150ms ease;
  fill: #ADADAD;
}

.close:hover path {
  fill: black
}
</style>
