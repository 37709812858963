import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_9MY4eAfYMX from "/app/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import plugin_UC48QI1yOB from "/app/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_client_sQhJOBiqXf from "/app/plugins/error-handler.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import filters_VNgPjx6zHj from "/app/plugins/filters.ts";
import me_YJ4xT53NdQ from "/app/plugins/me.ts";
import toast_client_9z8IIRO0Dx from "/app/plugins/toast.client.ts";
import vue_awesome_paginate_l1b0wTlGPB from "/app/plugins/vue-awesome-paginate.js";
import vue_draggable_UPVdqOrVyH from "/app/plugins/vue-draggable.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_9MY4eAfYMX,
  plugin_UC48QI1yOB,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  error_handler_client_sQhJOBiqXf,
  sentry_client_shVUlIjFLk,
  api_GFfDXud5Cr,
  filters_VNgPjx6zHj,
  me_YJ4xT53NdQ,
  toast_client_9z8IIRO0Dx,
  vue_awesome_paginate_l1b0wTlGPB,
  vue_draggable_UPVdqOrVyH
]