<script setup lang="ts">
import { vMaska } from 'maska'
import RButton from '~/components/rButton.vue'
import RInfo from '~/components/rInfo.vue'

const props = defineProps({
  actionLabel: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  validation: {
    type: Object,
    required: false,
    default: () => {},
  },
  value: {
    type: String || Number,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  labelPosition: {
    type: String,
    default: 'left',
    validator: value => ['top', 'left', 'right', 'bottom'].includes(value),
  },
  labelFont: {
    type: String,
    default: 'text-primary',
  },
  width: {
    type: String,
    required: false,
    default: '400px',
  },
  labelGap: {
    type: String,
    default: '2',
  },
  wrapperSpacing: {
    type: String,
    default: null,
  },
  infoText: {
    type: String,
    default: null,
  },
  autocomplete: {
    type: String,
    required: false,
    default: 'on',
  },
  textarea: {
    type: Boolean,
    required: false,
    default: false,
  },
  labelContain: {
    type: Boolean,
    required: false,
    default: false,
  },
  checkbox: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
})
defineEmits(['update:modelValue', 'update:value', 'resetForm', 'action'])
const inputValue = toRef(props, 'value')
</script>

<template>
  <div
    class="text-primary flex"
    :class="[
      labelPosition === 'top' ? 'flex-col' : 'items-center flex-row',
      `gap-${labelGap}`,
      `${wrapperSpacing}`,
    ]"
  >
    <div v-if="label || infoText" class="flex gap-1">
      <label
        v-if="label"
        class="whitespace-nowrap text-black"
        :class="{
          labelFont,
          'min-w-[128px]': !infoText && !labelContain,
          'max-w-[128px]': !infoText && !labelContain,
        }"
        :for="label"
      >{{ props.label }}</label>
      <RInfo v-if="infoText" class="-top-1">
        {{ infoText }}
      </RInfo>
    </div>

    <div :style="`width: ${width}`" class="relative">
      <div
        v-if="$attrs.maxlength"
        class="absolute -top-6 right-1 text-primary-small"
        :class="{
          'text-orange-500': $attrs.maxlength - inputValue.length < 3,
          'text-secondary-500': $attrs.maxlength - inputValue.length >= 3,
        }"
      >
        <span id="counter">{{ inputValue?.length }} / {{ $attrs.maxlength }}</span>
      </div>
      <div
        v-if="icon"
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <Icon :name="icon" />
      </div>
      <div class="flex flex-row gap-3">
        <input
          v-if="!textarea && !checkbox"
          v-maska
          v-bind="$attrs"
          :value="inputValue"
          :autocomplete="autocomplete"
          :data-maska="type === 'tel' ? '+7 $## ###-##-##' : ''"
          data-maska-tokens="{ '$': { 'pattern': '[9]' }}"
          data-maska-eager
          :placeholder="type === 'tel' ? '+7 ___ ___-__-__' : placeholder"
          :disabled="props.disabled"
          :name="label"
          class="border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          :type="type"
          :style="`width: ${width}`"
          :class="{
            'min-w-[400px]': !width,
            'max-w-[400px]': !width,
            'text-h5': type === 'password',
            'text-primary': type !== 'password',
            'pl-10': !!icon,
            'border-orange-500 bg-orange-100 hover:border-orange-500':
              props.validation?.$error,
            'border-transparent bg-secondary-100': !props.validation?.$error,
            'opacity-50': props.isLoading,
          }"
          @input="$emit('update:value', $event.target.value)"
          @focus="$emit('resetForm')"
        >
        <textarea
          v-if="textarea"
          v-bind="$attrs"
          :value="inputValue"
          :disabled="props.disabled"
          :name="label"
          :placeholder="placeholder"
          class="pt-2 leading-5 min-h-[100px] border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
          :style="`width: ${width}`"
          :class="{
            'min-w-[400px]': !width,
            'max-w-[400px]': !width,
            'text-h5': type === 'password',
            'text-primary': type !== 'password',
            'pl-8': !!icon,
            'border-orange-500 bg-orange-100 hover:border-orange-500':
              props.validation?.$error,
            'border-transparent bg-secondary-100': !props.validation?.$error,
          }"
          @input="$emit('update:value', $event.target.value)"
          @focus="$emit('resetForm')"
        />
        <div v-if="checkbox" class="flex items-center justify-center">
          <label
            class="relative inline-flex items-center cursor-pointer"
            @focus="$emit('resetForm')"
          >
            <input
              v-model="inputValue"
              :true-value="1"
              :false-value="0"
              type="checkbox"
              class="sr-only peer"
              @change="$emit('update:value', $event.target.checked ? 1 : 0)"
            >
            <div
              class="w-11 h-6 bg-gray-200 peer-focus:outline-non rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500 duration-150"
            />
          </label>
        </div>
        <RButton v-if="actionLabel" :is-loading="props.isLoading" @click="$emit('action')">
          {{ actionLabel }}
        </RButton>
      </div>
      <span class="text-primary-small text-secondary-500 mt-2">
        <slot class="" />
      </span>
    </div>
    <template v-if="props.validation">
      <span v-show="props.validation.$error" class="show" v-text="props?.validation?.$errors[0]?.$message" />
    </template>
  </div>
</template>

<style>
@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show {
  opacity: 0;
  animation: show 200ms ease-in-out;
  animation-fill-mode: forwards;
}
</style>
